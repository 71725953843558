import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import Layout from '../components/layout'

const Contacts = () => {
  const data = useStaticQuery(graphql`
    query {
      configJson {
        phone
        phoneLink
        town
        mail
        mailLink
      }
    }
  `)
  return (
    <Layout title="Контакты" description="Контакты" brcr={['Контакты']}>
      <section className="contactsPage">
        <Container className={`position-relative`}>
          <h1>Контакты</h1>
          <Row>
            <Col md={12}>
              <div>
                {/* <ul>
                  <li>Менеджеры</li>
                  <li>
                    <span>Станислав: </span>
                  </li>
                  <li>
                    <span>Тел:</span> <a href="tel:+79182705333">+7 918 270-53-33</a>,{' '}
                    <a href="https://api.whatsapp.com/send?phone=79182705333&text=Здравствуйте,">
                      whatsapp
                    </a>
                  </li>
                </ul> */}

                <Row>
                  <Col md={6}>
                    <h2>Заказ</h2>
                    <ul>
                      <li>
                        <span>Эл. почта:</span>{' '}
                        <a href={`mailto:${data.configJson.mailLink}`}>{data.configJson.mail}</a>
                      </li>
                    </ul>
                    <h2>Россия</h2>
                    <ul className="alt">
                      <li>Производство</li>
                      <li>
                        <span>Адрес: </span> Свердловская область, пгт. Верхнее Дуброво, ул.
                        Техническая 4а
                      </li>
                      <li>
                        <span>Тел:</span>{' '}
                        <a href={data.configJson.phoneLink}>{data.configJson.phone}</a>, Дмитрий
                      </li>
                    </ul>
                    <ul className="alt">
                      <li>Выставка образцов</li>
                      <li>
                        <span>Адрес: </span> Свердловская область г.Екатеринбург, пер.Базовый, 45.
                      </li>
                      <li>
                        <span>Тел:</span>{' '}
                        <a href={data.configJson.phoneLink}>{data.configJson.phone}</a>, Дмитрий
                      </li>
                    </ul>
                    <ul className="alt">
                      <li>Выставка образцов</li>
                      <li>
                        <span>Адрес:</span> Тюменская область г.Тюмень Закалужская улица, 83к4
                      </li>
                      <li>
                        <span>Тел:</span> <a href="tel:+79526890198">+7 (952) 689-01-98</a>, Денис
                      </li>
                    </ul>
                    <ul className="alt">
                      <li>Производство, выставка образцов</li>
                      <li>
                        <span>Адрес:</span> Республика Адыгея. пос. Яблоновский, Тургеневское шоссе
                        2В
                      </li>
                      <li>
                        <span>Тел:</span> <a href="tel:+79182705333">+7 (918) 270-53-33</a>, Андрей
                      </li>
                    </ul>
                    <ul className="alt">
                      <li>Производство</li>
                      <li>
                        <span>Адрес:</span> Республика Адыгея. пос. Яблоновский, ул. Морская 5
                      </li>
                      <li>
                        <span>Тел:</span> <a href="tel:+79182705333">+7 (918) 270-53-33</a>, Андрей
                      </li>
                    </ul>
                  </Col>

                  <Col md={6}>
                    <h2>Казахстан</h2>
                    <ul className="alt">
                      <li>Выставка образцов</li>
                      <li>
                        <span>Адрес:</span> Казахстан г. Алматы ул. Ырысты 46/2а
                      </li>
                      <li>
                        <span>Тел:</span> <a href="tel:+77023665666">+7-702-366-56-66</a>, Илим
                      </li>
                    </ul>
                    <ul className="alt">
                      <li>Выставка образцов</li>
                      <li>
                        <span>Адрес:</span> Восточно-Казахстанская область, г. Усть-Каменогорск, ул.
                        Казахстан 158а
                      </li>
                      <li>
                        <span>Тел:</span> <a href="tel:+77755233444">+7-775-52-33-444</a>, Алексей
                      </li>
                    </ul>
                    <ul className="alt">
                      <li>Выставка образцов</li>
                      <li>
                        <span>Адрес:</span> Акмолинская область г. Нур-Султан, ул. Чехоева 5
                        (центральный рынок) Трасса Астана - Караганда 9а
                      </li>
                      <li>
                        <span>Тел:</span> <a href="tel:+77718333348">+7-771-833-33-48</a>, Азамат
                      </li>
                      {/* <li>
                        <span>Тел:</span> <a href="tel:+77751425136">+7-775-142-51-36</a>, Сабит
                      </li>
                      <li>
                        <span>Тел:</span> <a href="tel:+77780082002">+7-778-008-20-02</a>, Азамат
                      </li> */}
                    </ul>
                    <ul className="alt">
                      <li>Выставка образцов</li>
                      <li>
                        <span>Адрес:</span> Северо-Казахстанская область, г. Петропавловск, ул. А.
                        Шажимбаева 93
                      </li>
                      <li>
                        <span>Тел:</span> <a href="tel:+77715342544">+7-771-534-25-44</a>, Семён
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={12}>
              <YMaps>
                <Map
                  width="auto"
                  height={400}
                  defaultState={{ center: [56.7652567, 61.0408673], zoom: 12 }}
                >
                  <Placemark geometry={[56.7652567, 61.0408673]} />
                </Map>
              </YMaps>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default Contacts
